import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const container = this.element;
    container.querySelectorAll("input[type=checkbox]").forEach((element) => {
      element.addEventListener("change", function () {
        if (element.checked) {
          const checkedElement = container.querySelector(
            `input[type=checkbox]:not(#${element.id}):checked`
          );
          if (checkedElement) checkedElement.checked = false;
        }
      });
    });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['submitButton'];

  connect() {
    const form = document.getElementsByClassName('simple_form')[0];
    const submitButton = this.submitButtonTarget;

    function blockButton() {
      submitButton.disabled = true;
    };

    form.addEventListener("submit", () => blockButton());
  };
};
import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["select"];
  static values = { options: Array, labelField: String };

  connect() {
    const labelField = this.labelFieldValue || "name";

    new TomSelect(this.selectTarget, {
      valueField: "id",
      labelField: labelField,
      searchField: [labelField],
      maxItems: 1,
      loadThrottle: 1000,
      options: this.optionsValue,
      items: [this.selectTarget.value],
    });
  }
}

import { Controller } from "@hotwired/stimulus";
import Toastify from "toastify-js";

export default class extends Controller {
  static targets = ["trigger"];
  static values = {
    text: String,
  };
  connect() {
    const text = this.textValue;
    this.triggerTarget.addEventListener("click", () => {
      navigator.clipboard.writeText(text);
      Toastify({
        text: "URL successfully copied to your clipboard.",
        duration: 3000,
        gravity: "top",
        position: "center",
        style: {
          // background: "linear-gradient(to right, #21C45D, #192638)",
          background: "#192638",
        },
      }).showToast();
    });
  }
}

import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ['list'];
  static values = {
    inputSelector: String,
    childrenSelector: String,
  };

  connect() {
    const scope = this;
    this.sortable = Sortable.create(this.listTarget, {
      onSort: () => scope.onSort(scope),
    });
  }

  onSort(scope) {
    const selector = scope.inputSelectorValue;
    const allItems = scope.listTarget.querySelectorAll(
      scope.childrenSelectorValue
    );
    for (let i = 0; i < allItems.length; i++) {
      const item = allItems[i];
      // console.log(item, selector);
      item.querySelector(selector).value = i;
    }
  }
}

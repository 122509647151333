import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    const input = this.inputTarget;
    const maskElem = SimpleMaskMoney.setMask(input, {
      prefix: "",
      suffix: "",
      fixed: true,
      fractionDigits: 2,
      decimalSeparator: ",",
      thousandsSeparator: ".",
      emptyOrInvalid: () => {
        return this.SimpleMaskMoney.args.fixed
          ? `0${this.SimpleMaskMoney.args.decimalSeparator}00`
          : `_${this.SimpleMaskMoney.args.decimalSeparator}__`;
      },
    });
    input.form.onsubmit = () => {
      input.value = maskElem.formatToNumber();
    };
  }
}

import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["select"];
  static values = { options: Array, selecteds: Array };

  connect() {
    console.log(this.selectedsValue);
    new TomSelect(this.selectTarget, {
      valueField: "id",
      labelField: ["name"],
      searchField: ["name"],
      maxItems: 1,
      options: this.optionsValue,
      items: this.selectedsValue,
    });
  }
}
